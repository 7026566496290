import React, { useState } from 'react'
import { FaArrowAltCircleRight, FaArrowAltCircleLeft, FaPython, FaGitAlt } from 'react-icons/fa'
import { DiJavascript1, DiHtml5, DiCss3, DiReact, DiNodejsSmall, DiUnitySmall, DiPostgresql, DiMongodb, DiDjango } from 'react-icons/di'
import { SiExpress } from 'react-icons/si'
import HoverImage from "react-hover-image";

//https://i.ibb.co/XpjvMcr/image.png
//https://i.ibb.co/HhmPF6V/image.png



export default function Portfolio() {
  const sliderData = [
    { image: 'https://i.ibb.co/XVV1Zd5/wp4678514-crypto-wallpapers.jpg', hoverImage: 'https://i.ibb.co/NZn0PLY/wp4678514-crypto-wallpapers.jpg', alt: 'Puny Swap', link: 'https://puny-swap-final.herokuapp.com/' },//add react app here
    { image: 'https://i.ibb.co/XZ3G3dP/teahub-io-lovely-friends-wallpapers-1103189.jpg', hoverImage: 'https://i.ibb.co/8NTNX3v/teahub-io-lovely-friends-wallpapers-1103189.jpg', alt: 'Daily Community', link: 'https://dailycommunity.herokuapp.com/' }, //django
    { image: 'https://i.ibb.co/SxgYj2m/1486722.jpg', hoverImage: 'https://i.ibb.co/q9pvmhN/1486722.jpg', alt: 'BattleShip', link: 'https://brandonsamaroo.com/Battle-Ship/' }, //battleship
    { image: 'https://i.ibb.co/pQcShdp/3308617.png', hoverImage: 'https://i.ibb.co/GQhmzLC/3308617.jpg', alt: 'Quizle', link: 'https://quizlesei.herokuapp.com/' }, //express
  ]
  const length = sliderData.length;
  const [current, setCurrent] = useState(0);
  if (!Array.isArray(sliderData) || sliderData.length <= 0) {
    return null
  }

  const previousSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1)
  }

  return (
    <div id="portfolio" className='content-section'>
      <h3>Projects</h3>
      <section className='slider'>
        <FaArrowAltCircleLeft className="left" onClick={previousSlide} />
        {sliderData.map((slide, idx) =>
        (
          <a href={slide.link} target="_blank" rel="noreferrer" className={idx === current ? 'slide active' : 'slide'} key={idx}>
            {idx === current && (<HoverImage src={slide.image} hoverSrc={slide.hoverImage} className="image"></HoverImage>)}
          </a>
        )
        // <img src={slide.image} alt={slide.alt} className="image"></img>
        )}
        <FaArrowAltCircleRight className="right" onClick={nextSlide} />
      </section>
      <h3>Skills</h3>
      <div className='skills-container'>
        <FaPython className='skill-icon' />
        <p>Python</p>
        <DiJavascript1 className='skill-icon' />
        <p>Javascript</p>
        <DiHtml5 className='skill-icon' />
        <p>HTML5</p>
        <DiCss3 className='skill-icon' />
        <p>CSS</p>
        <DiReact className='skill-icon' />
        <p>React</p>
        <DiNodejsSmall className='skill-icon' />
        <p>Node.js</p>
        <DiUnitySmall className='skill-icon' />
        <p>Unity</p>
        <DiPostgresql className='skill-icon' />
        <p>Postgresql</p>
        <DiMongodb className='skill-icon' />
        <p>MongoDB</p>
        <SiExpress className='skill-icon' />
        <p>Express</p>
        <FaGitAlt className='skill-icon' />
        <p>Git</p>
        <DiDjango className='skill-icon' />
      </div>
    </div>
  )
}

