import React, { Component } from 'react'


export default class Home extends Component {
  render() {
    return (
      <div id="home" className='content-section'>
        <h1>Hey! I'm Brandon Samaroo...</h1>
      </div>
    )
  }
}
